import React from "react";

import { List, BooleanField, Filter, Datagrid, TextField, SearchInput, DateField, } from 'react-admin';


const VersionFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder='Platform' source='platform' resettable alwaysOn />
    </Filter>
);

const VersionsList = (props) => (
    <List {...props} filters={<VersionFilter />} title='List of Versions'>
        <Datagrid rowClick="edit">
            <TextField source="version" />
            <TextField source="platform" />
            <BooleanField source="is_critical" />
            <DateField source="release_date" />
        </Datagrid>
    </List>
);


export default VersionsList;