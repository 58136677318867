import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    BooleanInput,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';


const VersionsCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify(`New app version created`);
        redirect(`/app_versions`);
        refresh();
    };

    return (
        <Create {...props} titile='Create version' onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput source='version' />
                <SelectInput
                    source='platform'
                    choices={[
                        { id: 'ios', name: 'ios' },
                        { id: 'android', name: 'android' },
                    ]}
                />
                <BooleanInput source="is_critical" defaultValue={false} />
                <DateInput source="release_date" />
            </SimpleForm>
        </Create>
    );
};


export default VersionsCreate;