import React, { Fragment, useState } from "react";
import {
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    useCreate,
    useNotify,
    useRecordContext,
    useTranslate,
    useLogout,
} from "react-admin";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconCancel from "@mui/icons-material/Cancel";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const RebootDialog = ({ open, loading, onClose, onSend }) => {
    const translate = useTranslate();

    const RebootToolbar = props => (
        <Toolbar {...props}>
            <SaveButton
                label="resources.reboot.action.confirm"
                disabled={props.pristine}
            />
            <Button label="ra.action.cancel" onClick={onClose}>
                <IconCancel />
            </Button>
        </Toolbar>
    );

    return (
        <Dialog open={open} onClose={onClose} loading={loading}>
            <DialogTitle>
                {translate("resources.reboot.action.reboot")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate("resources.reboot.helper.reboot")}
                </DialogContentText>
                <SimpleForm
                    toolbar={<RebootToolbar />}
                    submitOnEnter={false}
                    redirect={false}
                    save={onSend}
                >
                    <TextInput
                        source="body"
                        label="resources.reboot.fields.body"
                        fullWidth
                        multiline
                        rows="2"
                        resettable
                        validate={required()}
                    />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

export const RebootButton = props => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [create, { loading }] = useCreate("reboot");
    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const logout = useLogout();

    const handleSend = values => {
        create(
            { payload: { data: { id: record.id, ...values } } },
            {
                onSuccess: () => {
                    notify("resources.reboot.action.reboot_success");
                    handleDialogClose();
                    logout();
                },
                onFailure: (error) => {
                    if (error.status === 406) {
                        notify(error.message, {
                            type: "error",
                        });
                        handleDialogClose();
                    } else {
                        notify("resources.reboot.action.reboot_failure", {
                            type: "error",
                        });
                    }
                }
            }
        );
    };

    return (
        <Fragment>
            <Button
                label="resources.reboot.reboot"
                onClick={handleDialogOpen}
                disabled={loading}
            >
                <RestartAltIcon />
            </Button>
            <RebootDialog
                open={open}
                onClose={handleDialogClose}
                onSend={handleSend}
            />
        </Fragment>
    );
};


export const RebootBulkButton = ({ selectedIds }) => {

    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [create, { loading }] = useCreate("reboot");

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const logout = useLogout();

    const handleSend = values => {
        create(
            { payload: { data: { id: selectedIds[0], ...values } } },
            {
                onSuccess: () => {
                    notify("resources.reboot.action.reboot_success");
                    handleDialogClose();
                    logout();
                },
                onFailure: (error) => {
                    if (error.status === 406) {
                        notify(error.message, {
                            type: "error",
                        });
                        handleDialogClose();
                    } else {
                        notify("resources.reboot.action.reboot_failure", {
                            type: "error",
                        });
                    }
                }
            }
        );
    };

    return (
        <Fragment>
            <Button
                label="resources.reboot.reboot"
                onClick={handleDialogOpen}
                disabled={loading}
            >
                <RestartAltIcon />
            </Button>
            <RebootDialog
                open={open}
                onClose={handleDialogClose}
                onSend={handleSend}
            />
        </Fragment>
    );
};
