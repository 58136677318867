import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    BooleanInput
} from 'react-admin';


const VersionsEdit = (props) => (
    <Edit {...props} titile='Edit of Versions'>
        <SimpleForm>
            <TextInput disabled source='id' />
            <TextInput source='version' />
            <SelectInput
                source='platform'
                choices={[
                    { id: 'ios', name: 'ios' },
                    { id: 'android', name: 'android' },
                ]}
            />
            <BooleanInput source="is_critical" />
            <DateInput source="release_date" />
        </SimpleForm>
    </Edit>

)

export default VersionsEdit;